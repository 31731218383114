import React, { useEffect, useState } from 'react'
import { Row, Col, Button, message } from 'antd'
import styles from './NewCampaignFooter.module.scss'
import right_arrow from 'common/assets/svg/right_arrow_white.svg'
import power_button from 'common/assets/svg/power_button.svg'
import LaunchCampaignModal from './UI/LaunchCampaignModal'
// import { useAppSelector } from 'state'
import {
  useLazyCreateCampaignQuery,
  useLazyDeleteCampaignRecipientsQuery,
  useLazyGetCampaignsRecipientsByIdQuery,
  useLazySetCampaignsActiveQuery,
  useLazySetCampaignsSettingsQuery,
  useLazyUpdateCampaignQuery,
  // useLazyUpdateCampaignScheduleQuery,
} from 'features/Outreach/state/api/OutreachApi'
import { useAppDispatch, useAppSelector } from 'state'

import { SET_CAMPAIGN_ID, SET_CAMPAIGN_NAME, SET_SELECTED_LEADS } from '../state/outreachCampaignSlice'
import { useNavigate } from 'react-router-dom'
import { post } from 'common/api/axios'
import RemoveLeadsModal from '../EditCampaign/UI/RemoveLeadsModal'
import { transformToNewStructure } from './transformStepsUtil'

interface NewCampaignFooterProps {
  currentStep: any
  setCurrentStep?: any
  current: number
}

const NewCampaignFooter: React.FC<NewCampaignFooterProps> = ({ setCurrentStep, currentStep, current }) => {
  const [mixpanelData, setMixpanelData] = useState<any>()
  const [refetcSetCampaignsSettings] = useLazySetCampaignsSettingsQuery()
  const [refetcGetCampaignRecipients] = useLazyGetCampaignsRecipientsByIdQuery()
  const [refetcSetCampaignsActive] = useLazySetCampaignsActiveQuery()
  const [refetchCreateCampaign, { data: campaignData }] = useLazyCreateCampaignQuery()
  const [refetchDeleteRecipients] = useLazyDeleteCampaignRecipientsQuery()
  const [refetchUpdateCampaign] = useLazyUpdateCampaignQuery()
  const [openLaunchCampaignModal, setOpenLaunchCampaignModal] = useState(false)
  const [disabledButton, setDisabledButton] = useState(true)
  const [openRemoveLeadsModal, setOpenRemoveLeadsModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { sequence_data, template, campaign_id, campaign_name, selected_leads, senders_approved, isCompareAbTesting } =
    useAppSelector((state) => state.outreachCampaign)
  const steps = sequence_data.steps

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const getTelegramTableData = (user: any) => {
    const OutreachAccounts =
      user?.outreach?.map((user: any) => ({
        id: user.id,
        phoneNumber: `${user.area_code}${user.number}`,
        handle: `@${user.userName ? user.userName : 'N/A'}`,
        number: user.number,
        area_code: user.area_code,
        managed: user?.isManaged,
        status: 'Connected',
        type: 'Outreach',
        name: user.sender_full_name,
        company: user.sender_company,
      })) || []

    return OutreachAccounts
  }

  useEffect(() => {
    // const steps = sequence_data.steps

    if (
      steps &&
      ((template === 0 && Object.keys(steps)?.length > 1) ||
        (template === 1 && Object.keys(steps)?.length > 3) ||
        (template === 2 && Object.keys(steps)?.length > 6) ||
        (template === 3 && Object.keys(steps)?.length > 6))
    ) {
      let everythingIsFull = true
      // if (
      //   sequence_data.campaign_name === 'New Campaign Name'
      //   // sequence_data.campaign_description === 'New Campaign Description'
      // ) {
      //   everythingIsFull = false
      // }

      Object.keys(steps).forEach((key) => {
        const subObject = steps[key]
        if (subObject.type === 'send_telegram_message' || subObject.type === 'send_linkedin_message') {
          const { sender, messages } = subObject.data
          if (
            sender?.length === 0 ||
            !sender ||
            !messages ||
            messages?.filter((m: string) => m && m !== '')?.length === 0
          ) {
            everythingIsFull = false
          }
          const is_sender_full_name = messages.some((message: any) => message && message.includes('@sender_full_name'))
          const is_sender_company = messages.some((message: any) => message && message.includes('@sender_company'))

          if (is_sender_full_name || is_sender_company) {
            const local_user = localStorage.getItem('user')

            const telegramTableData = getTelegramTableData(JSON.parse(local_user || '{}'))
            const name = telegramTableData?.filter(
              (obj: any) => obj.name === '' || obj.name === null || obj.name === undefined,
            ).length
            const company = telegramTableData?.filter(
              (obj: any) => obj.company === '' || obj.company === null || obj.company === undefined,
            ).length
            if ((is_sender_full_name && name > 0) || (is_sender_company && company > 0)) {
              everythingIsFull = false
            }
          }
          if (isCompareAbTesting) {
            everythingIsFull = false
          }
        } else if (subObject.type === 'is_wait') {
          const { duration, units } = subObject.data
          if (!duration || !units) {
            if (duration !== 0) everythingIsFull = false
          }
        } else if (subObject.type === 'is_message_replied') {
          const { duration, units } = subObject.data
          if (!duration || !units) {
            if (duration !== 0) everythingIsFull = false
          }
        }
      })
      if (everythingIsFull) {
        setDisabledButton(false)
      } else {
        setDisabledButton(true)
      }
    }
  }, [sequence_data, steps, senders_approved, isCompareAbTesting])

  useEffect(() => {
    if (campaignData !== undefined) {
      const { campaign_id, campaign_name } = campaignData?.response
      dispatch(SET_CAMPAIGN_ID(campaign_id))
      dispatch(SET_CAMPAIGN_NAME(campaign_name))
    }
  }, [campaignData])

  const handleCreateCampaign = async () => {
    const campaign_name = sequence_data.campaign_name
    const campaign_description =
      sequence_data.campaign_description === 'New Campaign Description' ? undefined : sequence_data.campaign_description
    const status = sequence_data.status
    const steps = sequence_data.steps
    const settings = sequence_data.settings
    setMixpanelData({ campaign_name, campaign_id, status, settings })

    const dataArray = Object.keys(steps).map((key) => ({
      step_id: key,
      ...steps[key],
    }))

    const messages_steps = template === 3 || template === 4 ? transformToNewStructure(dataArray, 'linkedin') : undefined

    if (!campaign_id) {
      await refetchCreateCampaign({
        campaign_name,
        campaign_description,
        status,
        steps,
        messages_steps,
        settings,
        platform: template === 3 || template === 4 ? 'linkedin' : 'telegram',
      })
    } else {
      await refetchUpdateCampaign({
        campaign_id,
        campaign_name,
        campaign_description,
        status,
        steps,
        settings,
        edit_mode: false,
        messages_steps,
        platform: template === 3 || template === 4 ? 'linkedin' : 'telegram',
      })
    }
  }

  const buttonText =
    currentStep === 0 ? (
      <div className={styles.continue}>
        <span>Continue</span>
        <img src={right_arrow} alt='' />
      </div>
    ) : (
      <div className={styles.launch}>
        <img src={power_button} alt='' />
        <span>Launch Campaign</span>
      </div>
    )

  const handleButtonClick = () => {
    if (currentStep === 0) {
      handleCreateCampaign()
      setCurrentStep(1)
    } else {
      setOpenLaunchCampaignModal(true)
      post('/log', {
        action: 'Launch Campaign',
        data: mixpanelData,
      })
    }
  }

  const handleLaunchCampaign = (values: any) => {
    const data = { start_date: '', schedule_id: values.runInBetweenHours }
    data.start_date = values.whenToStart

    const launchCampaign = async () => {
      try {
        await refetcSetCampaignsSettings({ ...values, ...data, campaign_id, campaign_name })
        await refetcSetCampaignsActive({ campaign_ids: [campaign_id], is_active: true, status: 'running' })
        setOpenLaunchCampaignModal(false)
      } catch (e) {
        console.error(e)
      } finally {
        navigate('/outreach')
      }
    }
    launchCampaign()
  }
  const handleButtonRemoveClick = () => {
    setOpenRemoveLeadsModal(true)
  }

  const handleRemoveLeads = async () => {
    try {
      setIsLoading(true)
      await refetchDeleteRecipients({
        campaign_id: campaign_id,
        recipient_ids: selected_leads,
      })
      await refetcGetCampaignRecipients({ campaign_id: campaign_id, page: current, limit: 50 })
      dispatch(SET_SELECTED_LEADS([]))
      setOpenRemoveLeadsModal(false)
      setIsLoading(false)
      message.success('Leads removed successfully!')
    } catch (e) {
      console.error('Error removing leads: ', e)
    }
  }
  return (
    <Row
      justify='space-between'
      align='middle'
      className={styles.header}
      style={{
        // marginTop: '40px',
        padding: '15px 35px',
        backgroundColor: 'white',
        fontWeight: 600,
        zIndex: 1000,
      }}>
      <Col flex='auto' span={3}>
        {currentStep === 1 && (
          <div className={styles.leftSide}>
            <Button onClick={handleButtonRemoveClick} disabled={selected_leads?.length === 0}>
              Remove Leads
            </Button>
          </div>
        )}
      </Col>
      <Col flex='auto' />
      <Col>
        <div className={styles.rightSide}>
          <Button onClick={handleButtonClick} disabled={disabledButton}>
            {buttonText}
          </Button>
        </div>
      </Col>
      {openLaunchCampaignModal && (
        <LaunchCampaignModal
          open={openLaunchCampaignModal}
          onClose={() => {
            setOpenLaunchCampaignModal(false)
          }}
          onFinish={handleLaunchCampaign}
          campaignId={campaign_id}
        />
      )}
      {openRemoveLeadsModal && (
        <RemoveLeadsModal
          open={openRemoveLeadsModal}
          onClose={() => {
            setOpenRemoveLeadsModal(false)
          }}
          campaignName={'this campaign'}
          onConfirm={handleRemoveLeads}
          isLoading={isLoading}
        />
      )}
    </Row>
  )
}

export default NewCampaignFooter
