import companiesFilter from 'common/assets/svg/companiesFilter.svg'
import intentsFilter from 'common/assets/svg/intentsFilter.svg'
import listsFilter from 'common/assets/svg/listsFilter.svg'
import lightning_filter_icon from 'common/assets/svg/lightning_filter_icon.svg'
// import locationsFilter from 'common/assets/svg/locationsFilter.svg'
import onchainFilter from 'common/assets/svg/onchainFilter.svg'
import techFilter from 'common/assets/svg/techFilter.svg'
import web2Filter from 'common/assets/svg/web2Filter.svg'
import revenue from 'common/assets/svg/revenue.svg'
import funding_filter from 'common/assets/svg/funding_filter.svg'
import { IFilter } from 'common/interfaces/IFilters'
import { chains } from './chainFilter'
import { countriesWithPhonePrefix } from './countires'
// import { usersBlockedList } from './userData'
import { fundingDates } from './fundingDates'
//const { userDetails } = useAuth()
//let userDetails: string | null = localStorage.getItem('user');

//JSON.parse(localStorage.getItem('user'))

// let userDetails = null
// if (localStorage.getItem('user') !== null) {
//   userDetails = JSON.parse(JSON.stringify(localStorage.getItem('user')))
// }

const user = JSON.parse(localStorage.getItem('user') || '{}')

const trafficCountries = countriesWithPhonePrefix.map(({ label, countryCode }) => ({
  label,
  value: countryCode?.toLowerCase() || '',
}))

type milestonesMapType = {
  [propKey: string]: { label: string; color: string; value: string; comingSoon?: boolean }
}

export const milestonesMap: milestonesMapType = {
  'Recently Raised': {
    label: 'Recently Funded',
    color: '#3ec7a8',
    value: 'Recently Raised',
    comingSoon: !user?.addons?.recently_raised,
  },
  // 'Launching product': { label: 'Fundraising', color: '#5fbcf8', value: 'Launching product', comingSoon: false },
  'Enterprise entering the space': {
    label: 'Enterprise Entering Web3',
    color: '#faad14',
    value: 'Enterprise entering the space',
    comingSoon: !user?.addons?.enterprise,
  },
  'Soon to be launched': {
    label: 'Raising Funds (TGE/ IDO)',
    color: '#ff4fe6',
    value: 'Soon to be launched',
    comingSoon: !user?.addons?.preparing_to_launch,
  },
  // Testnet: { label: 'Companies On Testnet', color: '#713ec7', value: 'Testnet', comingSoon: false },
  // Testnet: {
  //   label: 'Preparing to Launch',
  //   color: '#ff4fe6',
  //   value: 'Soon to be launched',
  //   comingSoon: !user?.addons?.preparing_to_launch,
  // },
  // 'building stage': {
  //   label: 'Building stage',
  //   color: '#713ec7',
  //   value: 'building stage',
  //   comingSoon: !user?.addons?.building_stage,
  // },
}

type interestsMapType = {
  [propKey: string]: { label: string; color: string; value: string; comingSoon?: boolean }
}

export const interestsMap: interestsMapType = {
  'Security Servicies': {
    label: 'Security Servicies',
    color: '#3ec7a8',
    value: 'Security and Audits',
    comingSoon: !user?.addons?.security,
  },
  Appchains: {
    label: 'Appchains',
    color: '#3ec7a8',
    value: 'Appchains',
    comingSoon: !user?.addons?.appchains,
  },
  'RPC & Data': {
    label: 'RPC & Data',
    color: '#3ec7a8',
    value: 'RPC & Data',
    comingSoon: !user?.addons?.rpc_data,
  },
  'Payment & remittence': {
    label: 'Payment & Remittence',
    color: '#3ec7a8',
    value: 'Payment & Remittence',
    comingSoon: !user?.addons?.payment_remittence,
  },
  'Agencies & Outsource': {
    label: 'Agencies & Outsource',
    color: '#3ec7a8',
    value: 'Agencies & Outsource',
    comingSoon: !user?.addons?.agencies_outsource,
  },
}

const chainsOptions = chains.map((chain) => {
  return {
    label: chain === 'select-all' ? 'Select All' : chain === 'multi-chains' ? 'MULTICHAIN' : chain.toUpperCase(),
    value: chain,
  }
})

export const companyFilter: IFilter[] = [
  {
    label: 'Lists',
    image: listsFilter,
    key: 'lists',
    value: '',
    type: 'listsFilter',
  },
  {
    label: 'Sequence',
    image: lightning_filter_icon,
    key: 'excludeLists',
    value: '',
    type: 'excludeListsFilter',
  },
  {
    label: 'Companies',
    image: companiesFilter,
    tooltip: `Start filtering by company information (Vertical, Milestones, Headcount and more). `,
    key: 'companies',
    value: '',
    type: 'expandGroupe',
    new: false,
    children: [
      {
        label: `Company's HQ`,
        // image: locationsFilter,
        key: 'companyHQ',
        value: '',
        type: 'companyHQ',
        new: false,
      },
      // {
      //   label: 'Company Name',
      //   type: 'input',
      //   key: 'companies.companyName',
      // },
      // {
      //   label: 'Vertical',
      //   type: 'three',
      //   key: 'companies.vertical',
      //   threeContent: [
      //     {
      //       title: 'Decentralized Finance (DeFi)',
      //       key: 'companies.vertical.defi',
      //       children: [
      //         {
      //           title: <span className='font-bold'>Select All</span>,
      //           key: 'companies.vertical.defi.select',
      //         },
      //         {
      //           title: 'DEX',
      //           key: 'companies.vertical.defi.dex',
      //         },
      //         {
      //           title: 'Yield Farming',
      //           key: 'companies.vertical.defi.yieldFarming',
      //         },
      //         {
      //           title: 'Yield Aggregator',
      //           key: 'companies.vertical.defi.yieldAggregator',
      //         },
      //         {
      //           title: 'Farm',
      //           key: 'companies.vertical.defi.farm',
      //         },
      //         {
      //           title: 'Vaults',
      //           key: 'companies.vertical.defi.vaults',
      //         },
      //         {
      //           title: 'DApp',
      //           key: 'companies.vertical.defi.dApp',
      //         },
      //         {
      //           title: 'Algo-Stables',
      //           key: 'companies.vertical.defi.algoStables',
      //         },
      //         {
      //           title: 'Leveraged Farming',
      //           key: 'companies.vertical.defi.levreged',
      //         },
      //         {
      //           title: 'Liquid Staking',
      //           key: 'companies.vertical.defi.liquidStaking',
      //         },
      //         {
      //           title: 'Automated Market Makers (AMM)',
      //           key: 'companies.vertical.defi.amm',
      //         },
      //         {
      //           title: 'Derivative Exchanges',
      //           key: 'companies.vertical.defi.derivativeExchanges',
      //         },
      //         {
      //           title: 'Swaps',
      //           key: 'companies.vertical.defi.swaps',
      //         },
      //         {
      //           title: 'Spot Trading',
      //           key: 'companies.vertical.defi.spotTrading',
      //         },
      //         {
      //           title: 'Stablecoins',
      //           key: 'companies.vertical.defi.stablecoins',
      //         },
      //         {
      //           title: 'Fiat-Backed Stablecoins',
      //           key: 'companies.vertical.defi.fiatStablecoins',
      //         },
      //         {
      //           title: 'Crypto-Backed Stablecoins',
      //           key: 'companies.vertical.defi.cryptoStablecoins',
      //         },
      //         {
      //           title: 'Collateralized Stablecoins',
      //           key: 'companies.vertical.defi.collateralizedStablecoins',
      //         },
      //         {
      //           title: 'Undercollateralized & Uncollateralized Loans',
      //           key: 'companies.vertical.defi.uncollateralizedLoans',
      //         },
      //         {
      //           title: 'Flash Loans',
      //           key: 'companies.vertical.defi.flashLoans',
      //         },
      //         {
      //           title: 'Yield Optimizers',
      //           key: 'companies.vertical.defi.yieldOptimizers',
      //         },
      //         {
      //           title: 'Asset Allocation & Rebalancing',
      //           key: 'companies.vertical.defi.assetAllocation',
      //         },
      //         {
      //           title: 'Lending & Borrowing Platforms',
      //           key: 'companies.vertical.defi.lendingAndborrowingPlatform',
      //         },
      //         {
      //           title: 'Liquidity',
      //           key: 'companies.vertical.defi.liquidity',
      //         },
      //         {
      //           title: 'Staking',
      //           key: 'companies.vertical.defi.stacking',
      //         },
      //         {
      //           title: 'Staking Pool',
      //           key: 'companies.vertical.defi.stackingPool',
      //         },
      //       ],
      //       disableCheckbox: true,
      //     },
      //     {
      //       title: 'Cryptocurrency Payments & Gateways',
      //       key: 'companies.vertical.cryptoPaymentsAndGateways',
      //       children: [
      //         {
      //           title: <span className='font-bold'>Select All</span>,
      //           key: 'companies.vertical.cryptoPaymentsAndGateways.select',
      //         },
      //         {
      //           title: 'CEX',
      //           key: 'companies.vertical.cryptoPaymentsAndGateways.cex',
      //         },
      //         {
      //           title: 'Online Payments',
      //           key: 'companies.vertical.cryptoPaymentsAndGateways.onlinePayments',
      //         },
      //         {
      //           title: 'Point of Sale (POS) Solutions',
      //           key: 'companies.vertical.cryptoPaymentsAndGateways.pointOfSaleSolutions',
      //         },
      //         {
      //           title: 'Fiat-to-Crypto Onboarding',
      //           key: 'companies.vertical.cryptoPaymentsAndGateways.fiatToCryptoOnboarding',
      //         },
      //         {
      //           title: 'Crypto-to-Fiat Exchanges',
      //           key: 'companies.vertical.cryptoPaymentsAndGateways.cryptoToFiatExchanges',
      //         },
      //         {
      //           title: 'Fiat-Backed Stablecoins',
      //           key: 'companies.vertical.cryptoPaymentsAndGateways.fiatBackedStablecoins',
      //         },
      //         {
      //           title: 'Peer-to-Peer Exchanges',
      //           key: 'companies.vertical.cryptoPaymentsAndGateways.peerToPeerExchanges',
      //         },
      //         {
      //           title: 'Wallet',
      //           key: 'companies.vertical.cryptoPaymentsAndGateways.wallet',
      //         },
      //       ],
      //       disableCheckbox: true,
      //     },
      //     {
      //       title: 'NFT & Digital Collectibles',
      //       key: 'companies.vertical.nftAndDigitalCollectables',
      //       children: [
      //         {
      //           title: <span className='font-bold'>Select All</span>,
      //           key: 'companies.vertical.nftAndDigitalCollectables.select',
      //         },
      //         {
      //           title: 'Collection',
      //           key: 'companies.vertical.nftAndDigitalCollectables.collection',
      //         },
      //         {
      //           title: 'NFT Marketplaces',
      //           key: 'companies.vertical.nftAndDigitalCollectables.nftMarketplaces',
      //         },
      //         {
      //           title: 'NFT Creation & Minting',
      //           key: 'companies.vertical.nftAndDigitalCollectables.nftCreationAndMinting',
      //         },
      //         {
      //           title: 'Custom NFT Solutions',
      //           key: 'companies.vertical.nftAndDigitalCollectables.customNftSolutions',
      //         },
      //         {
      //           title: 'NFT Trading',
      //           key: 'companies.vertical.nftAndDigitalCollectables.nftTrading',
      //         },
      //         {
      //           title: 'NFT Auctions',
      //           key: 'companies.vertical.nftAndDigitalCollectables.nftAuctions',
      //         },
      //         {
      //           title: 'Gaming & Virtual Worlds',
      //           key: 'companies.vertical.nftAndDigitalCollectables.gamingAndVirtualWorlds',
      //         },
      //         {
      //           title: 'Play-to-Earn Gaming Models',
      //           key: 'companies.vertical.nftAndDigitalCollectables.playToEarnGamingModels',
      //         },
      //         {
      //           title: 'Soulbound & Special-Use Tokens',
      //           key: 'companies.vertical.nftAndDigitalCollectables.soulboundAndSpecialUseTokens',
      //         },
      //         {
      //           title: 'Rare Digital Art',
      //           key: 'companies.vertical.nftAndDigitalCollectables.rareDigitalArt',
      //         },
      //         {
      //           title: 'Utility Tokens',
      //           key: 'companies.vertical.nftAndDigitalCollectables.utilityTokens',
      //         },
      //         {
      //           title: 'Governance Tokens',
      //           key: 'companies.vertical.nftAndDigitalCollectables.governanceTokens',
      //         },
      //         {
      //           title: 'Exclusive Content Access',
      //           key: 'companies.vertical.nftAndDigitalCollectables.exclusiveContentAccess',
      //         },
      //         {
      //           title: 'Tokenized Music Rights & Royalties',
      //           key: 'companies.vertical.nftAndDigitalCollectables.tokenizedMusicRightsAndRoyalties',
      //         },
      //         {
      //           title: 'Digital Ticketing Solutions',
      //           key: 'companies.vertical.nftAndDigitalCollectables.digitalTicketingSolutions',
      //         },
      //         {
      //           title: 'Live Performance NFTs',
      //           key: 'companies.vertical.nftAndDigitalCollectables.livePerformanceNfts',
      //         },
      //         {
      //           title: 'Event Ticketing & Verification',
      //           key: 'companies.vertical.nftAndDigitalCollectables.eventTicketingAndVerification',
      //         },
      //         {
      //           title: 'Anti-Scalping & Counterfeit Protection',
      //           key: 'companies.vertical.nftAndDigitalCollectables.antiScalpingAndCounterfeitProtection',
      //         },
      //       ],
      //       disableCheckbox: true,
      //     },
      //     {
      //       title: 'Payments & Remittance',
      //       key: 'companies.vertical.paymentsAndRemittance',
      //       children: [
      //         {
      //           title: <span className='font-bold'>Select All</span>,
      //           key: 'companies.vertical.paymentsAndRemittance.select',
      //         },
      //         {
      //           title: 'Cryptocurrency Payments & Gateways',
      //           key: 'companies.vertical.paymentsAndRemittance.cryptocurrencyPaymentsAndGateways',
      //         },
      //         {
      //           title: 'Online Payments',
      //           key: 'companies.vertical.paymentsAndRemittance.onlinePayments',
      //         },
      //         {
      //           title: 'Point of Sale (POS) Solutions',
      //           key: 'companies.vertical.paymentsAndRemittance.pointOfSaleSolutions',
      //         },
      //         {
      //           title: 'Merchant Services',
      //           key: 'companies.vertical.paymentsAndRemittance.merchantServices',
      //         },
      //         {
      //           title: 'Recurring Payments',
      //           key: 'companies.vertical.paymentsAndRemittance.recurringPayments',
      //         },
      //         {
      //           title: 'Subscription Payments',
      //           key: 'companies.vertical.paymentsAndRemittance.subscriptionPayments',
      //         },
      //         {
      //           title: 'Cross-Border Payments & Remittance',
      //           key: 'companies.vertical.paymentsAndRemittance.crossBorderPaymentsAndRemittance',
      //         },
      //         {
      //           title: 'International Money Transfer',
      //           key: 'companies.vertical.paymentsAndRemittance.internationalMoneyTransfer',
      //         },
      //         {
      //           title: 'Remittance Services',
      //           key: 'companies.vertical.paymentsAndRemittance.remittanceServices',
      //         },
      //       ],
      //       disableCheckbox: true,
      //     },
      //     {
      //       title: 'Gaming',
      //       key: 'companies.vertical.gaming',
      //       children: [
      //         {
      //           title: <span className='font-bold'>Select All</span>,
      //           key: 'companies.vertical.gaming.select',
      //         },
      //         {
      //           title: 'Gambling',
      //           key: 'companies.vertical.gaming.gambling',
      //         },
      //         {
      //           title: 'Gaming & Virtual Worlds',
      //           key: 'companies.vertical.gaming.gamingAndVirtualWorlds',
      //         },
      //         {
      //           title: 'Play-to-Earn Gaming',
      //           key: 'companies.vertical.gaming.playToEarnGaming',
      //         },
      //         {
      //           title: 'M2E',
      //           key: 'companies.vertical.gaming.m2e',
      //         },
      //         {
      //           title: 'Move-To-Earn',
      //           key: 'companies.vertical.gaming.moveToEarn',
      //         },
      //         {
      //           title: 'Metaverse',
      //           key: 'companies.vertical.gaming.metaverse',
      //         },
      //         {
      //           title: 'game',
      //           key: 'companies.vertical.gaming.game',
      //         },
      //         {
      //           title: 'vr game',
      //           key: 'companies.vertical.gaming.vrGame',
      //         },
      //       ],
      //       disableCheckbox: true,
      //     },
      //     {
      //       title: 'Web2 Companies',
      //       key: 'companies.vertical.web2Companies',
      //       children: [
      //         {
      //           title: <span className='font-bold'>Select All</span>,
      //           key: 'companies.vertical.web2Companies.select',
      //         },
      //         {
      //           title: 'Identity Verification',
      //           key: 'companies.vertical.web2Companies.identityVerification',
      //         },
      //         {
      //           title: 'Supply Chain & Logistics',
      //           key: 'companies.vertical.web2Companies.supplyChainAndLogistics',
      //         },
      //         {
      //           title: 'Healthcare & Medical Records',
      //           key: 'companies.vertical.web2Companies.healthcareAndMedicalRecords',
      //         },
      //         {
      //           title: 'Real Estate & Property Management',
      //           key: 'companies.vertical.web2Companies.realEstateAndPropertyManagement',
      //         },
      //         {
      //           title: 'Insurance & Claims Management',
      //           key: 'companies.vertical.web2Companies.insuranceAndClaimsManagement',
      //         },
      //         {
      //           title: 'Digital Advertising & Marketing',
      //           key: 'companies.vertical.web2Companies.digitalAdvertisingAndMarketing',
      //         },
      //         {
      //           title: 'Data Management & Analytics',
      //           key: 'companies.vertical.web2Companies.dataManagementAndAnalytics',
      //         },
      //         {
      //           title: 'Energy & Utilities',
      //           key: 'companies.vertical.web2Companies.energyAndUtilities',
      //         },
      //         {
      //           title: 'Gaming & Entertainment',
      //           key: 'companies.vertical.web2Companies.gamingAndEntertainment',
      //         },
      //         {
      //           title: 'Legal & Compliance',
      //           key: 'companies.vertical.web2Companies.legalAndCompliance',
      //         },
      //         {
      //           title: 'Legal & Regulatory Support',
      //           key: 'companies.vertical.web2Companies.legalAndRegulatorySupport',
      //         },
      //         {
      //           title: 'Growth & Marketing Services',
      //           key: 'companies.vertical.web2Companies.growthAndMarketingServices',
      //         },
      //         {
      //           title: 'Advisory Services & Consulting',
      //           key: 'companies.vertical.web2Companies.advisoryServicesAndConsulting',
      //         },
      //         {
      //           title: 'Recruitment & Talent Acquisition',
      //           key: 'companies.vertical.web2Companies.recruitmentAndTalentAcquisition',
      //         },
      //         {
      //           title: 'Technology Advisory',
      //           key: 'companies.vertical.web2Companies.technologyAdvisory',
      //         },
      //       ],
      //       disableCheckbox: true,
      //     },
      //     {
      //       title: 'Venture Capital & VC',
      //       key: 'companies.vertical.ventureCapital',
      //       children: [
      //         {
      //           title: <span className='font-bold'>Select All</span>,
      //           key: 'companies.vertical.ventureCapital.select',
      //         },
      //         {
      //           title: 'VC',
      //           key: 'companies.vertical.ventureCapital.vc',
      //         },
      //         {
      //           title: 'VC Firms',
      //           key: 'companies.vertical.ventureCapital.vcFirms',
      //         },
      //         {
      //           title: 'Angel Investors',
      //           key: 'companies.vertical.ventureCapital.angelInvestors',
      //         },
      //         {
      //           title: 'Institutional Investors',
      //           key: 'companies.vertical.ventureCapital.institutionalInvestors',
      //         },
      //         {
      //           title: 'Corporate Venture Capital',
      //           key: 'companies.vertical.ventureCapital.corporateVentureCapital',
      //         },
      //         {
      //           title: 'Government Grants',
      //           key: 'companies.vertical.ventureCapital.governmentGrants',
      //         },
      //         {
      //           title: 'Incubators',
      //           key: 'companies.vertical.ventureCapital.incubators',
      //         },
      //         {
      //           title: 'Accelerators & Incubators',
      //           key: 'companies.vertical.ventureCapital.acceleratorsAndIncubators',
      //         },
      //         {
      //           title: 'Technology Advisory',
      //           key: 'companies.vertical.ventureCapital.technologyAdvisory',
      //         },
      //         {
      //           title: 'Launchpad',
      //           key: 'companies.vertical.ventureCapital.launchpad',
      //         },
      //       ],
      //       disableCheckbox: true,
      //     },
      //     {
      //       title: 'Social',
      //       key: 'companies.vertical.social',
      //       children: [
      //         {
      //           title: <span className='font-bold'>Select All</span>,
      //           key: 'companies.vertical.social.select',
      //         },
      //         {
      //           title: 'Video',
      //           key: 'companies.vertical.social.video',
      //         },
      //         {
      //           title: 'Institutional Investors',
      //           key: 'companies.vertical.social.institutionalInvestors',
      //         },
      //         {
      //           title: 'Corporate Venture Capital',
      //           key: 'companies.vertical.social.corporateVentureCapital',
      //         },
      //         {
      //           title: 'Government Grants',
      //           key: 'companies.vertical.social.governmentGrants',
      //         },
      //         {
      //           title: 'Startup Incubators',
      //           key: 'companies.vertical.social.startupIncubators',
      //         },
      //         {
      //           title: 'Accelerators & Incubators',
      //           key: 'companies.vertical.social.acceleratorsAndIncubators',
      //         },
      //         {
      //           title: 'Technology Advisory',
      //           key: 'companies.vertical.social.technologyAdvisory',
      //         },
      //         {
      //           title: 'Social Network',
      //           key: 'companies.vertical.social.socialNetwork',
      //         },
      //         {
      //           title: 'Community',
      //           key: 'companies.vertical.social.community',
      //         },
      //         {
      //           title: 'Web3 Email',
      //           key: 'companies.vertical.social.web3Email',
      //         },
      //         {
      //           title: 'Web2 Communication',
      //           key: 'companies.vertical.social.web2Communication',
      //         },
      //       ],
      //       disableCheckbox: true,
      //     },
      //     {
      //       title: 'Infrastructure',
      //       key: 'companies.vertical.infrastructure',
      //       children: [
      //         {
      //           title: <span className='font-bold'>Select All</span>,
      //           key: 'companies.vertical.infrastructure.select',
      //         },
      //         {
      //           title: 'Privacy',
      //           key: 'companies.vertical.infrastructure.privacy',
      //         },
      //         {
      //           title: 'L1',
      //           key: 'companies.vertical.infrastructure.l1',
      //         },
      //         {
      //           title: 'Oracle',
      //           key: 'companies.vertical.infrastructure.oracle',
      //         },
      //         {
      //           title: 'Proof of Work (PoW)',
      //           key: 'companies.vertical.infrastructure.pow',
      //         },
      //         {
      //           title: 'Proof of Stake (PoS)',
      //           key: 'companies.vertical.infrastructure.pos',
      //         },
      //         {
      //           title: 'Delegated Proof of Stake (DPoS)',
      //           key: 'companies.vertical.infrastructure.dpos',
      //         },
      //         {
      //           title: 'Practical Byzantine Fault Tolerance (PBFT)',
      //           key: 'companies.vertical.infrastructure.pbft',
      //         },
      //         {
      //           title: 'Proof of Space (PoSpace) or Proof of Capacity (PoC)',
      //           key: 'companies.vertical.infrastructure.pocAndPoSpace',
      //         },
      //         {
      //           title: 'Federated Byzantine Agreement (FBA)',
      //           key: 'companies.vertical.infrastructure.fba',
      //         },
      //         {
      //           title: 'Directed Acyclic Graph (DAG) based consensus',
      //           key: 'companies.vertical.infrastructure.dag',
      //         },
      //         {
      //           title: 'Delegated Byzantine Fault Tolerance (DBFT)',
      //           key: 'companies.vertical.infrastructure.dbft',
      //         },
      //         {
      //           title: 'Proof of Elapsed Time (PoET)',
      //           key: 'companies.vertical.infrastructure.poET',
      //         },
      //         {
      //           title: 'Proof of Burn (PoB)',
      //           key: 'companies.vertical.infrastructure.pob',
      //         },
      //         {
      //           title: 'Proof of Importance (PoI)',
      //           key: 'companies.vertical.infrastructure.poi',
      //         },
      //         {
      //           title: 'Proof of Activity (PoA)',
      //           key: 'companies.vertical.infrastructure.poa',
      //         },
      //         {
      //           title: 'Tendermint Consensus',
      //           key: 'companies.vertical.infrastructure.tendermint_consensus',
      //         },
      //         {
      //           title: 'Nakamoto Consensus',
      //           key: 'companies.vertical.infrastructure.nakamoto_consensus',
      //         },
      //         {
      //           title: 'Avalanche Consensus',
      //           key: 'companies.vertical.infrastructure.avalanche_consensus',
      //         },
      //         {
      //           title: 'Honey Badger BFT',
      //           key: 'companies.vertical.infrastructure.bft',
      //         },
      //         {
      //           title: 'Rollups (Optimistic Rollups, ZK-Rollups)',
      //           key: 'companies.vertical.infrastructure.rollups',
      //         },
      //         {
      //           title: 'Plasma',
      //           key: 'companies.vertical.infrastructure.plasma',
      //         },
      //         {
      //           title: 'State Channels',
      //           key: 'companies.vertical.infrastructure.stateChannels',
      //         },
      //         {
      //           title: 'Sidechains',
      //           key: 'companies.vertical.infrastructure.sidechains',
      //         },
      //         {
      //           title: 'Validium',
      //           key: 'companies.vertical.infrastructure.validium',
      //         },
      //         {
      //           title: 'Volition',
      //           key: 'companies.vertical.infrastructure.volition',
      //         },
      //         {
      //           title: 'Sharding',
      //           key: 'companies.vertical.infrastructure.sharding',
      //         },
      //         {
      //           title: 'Commit Chains',
      //           key: 'companies.vertical.infrastructure.commitChains',
      //         },
      //         {
      //           title: 'Bridges & Cross-Chain Solutions',
      //           key: 'companies.vertical.infrastructure.ccs',
      //         },
      //         {
      //           title: 'Inter-Blockchain Communication (IBC)',
      //           key: 'companies.vertical.infrastructure.ibc',
      //         },
      //         {
      //           title: 'Bridges (Centralized & Decentralized)',
      //           key: 'companies.vertical.infrastructure.bridges',
      //         },
      //         {
      //           title: 'Cross-chain Swaps (Atomic Swaps)',
      //           key: 'companies.vertical.infrastructure.swaps',
      //         },
      //         {
      //           title: 'Shared Security',
      //           key: 'companies.vertical.infrastructure.sharedSecurity',
      //         },
      //         {
      //           title: 'Wrapped Tokens',
      //           key: 'companies.vertical.infrastructure.wrappedTokens',
      //         },
      //         {
      //           title: 'Multichain Architectures',
      //           key: 'companies.vertical.infrastructure.multichainArchitectures',
      //         },
      //       ],
      //       disableCheckbox: true,
      //     },
      //   ],
      // },
      {
        label: 'Vertical',
        type: 'three',
        key: 'companies.vertical',
        threeContent: [
          {
            title: 'Web3 Infrastructure',
            key: 'companies.vertical.web3Infrastructure',
          },
          {
            title: 'Developer Tools',
            key: 'companies.vertical.developerTools',
          },
          {
            title: 'Exchange',
            key: 'companies.vertical.exchange',
          },
          {
            title: 'DEX',
            key: 'companies.vertical.dex',
          },
          {
            title: 'DAO',
            key: 'companies.vertical.dao',
          },
          {
            title: 'DeFi',
            key: 'companies.vertical.defi',
          },
          {
            title: 'Data Provider',
            key: 'companies.vertical.dataProvider',
          },
          {
            title: 'Wallet',
            key: 'companies.vertical.wallet',
          },
          {
            title: 'NFT',
            key: 'companies.vertical.nft',
          },
          {
            title: 'Gaming',
            key: 'companies.vertical.gaming',
          },
          {
            title: 'Gambling',
            key: 'companies.vertical.gambling',
          },
          {
            title: 'L1',
            key: 'companies.vertical.l1',
          },
          {
            title: 'L2',
            key: 'companies.vertical.l2',
          },
          {
            title: 'Identity',
            key: 'companies.vertical.identity',
          },
          {
            title: 'Security and Audits',
            key: 'companies.vertical.securityAndAudits',
          },
          {
            title: 'Asset Management',
            key: 'companies.vertical.assetManagement',
          },
          {
            title: 'Mining Companies',
            key: 'companies.vertical.miningCompanies',
          },
          {
            title: 'Outsource & Agencies',
            key: 'companies.vertical.outsourceAndAgencies',
          },
          {
            title: 'Banking & Financial Institutions',
            key: 'companies.vertical.bankingAndFinancialInstitutions',
          },
          {
            title: 'NFT Marketplaces',
            key: 'companies.vertical.nftMarketplaces',
          },
          {
            title: 'Payments & Remittance',
            key: 'companies.vertical.paymentsRemittance',
          },
          {
            title: 'Data Platform & Analytics',
            key: 'companies.vertical.dataPlatformAnalytics',
          },
          {
            title: 'Web2 Companies',
            key: 'companies.vertical.web2Companies',
          },
          {
            title: 'Venture Capital',
            key: 'companies.vertical.VentureCapital',
          },
          {
            title: 'Social',
            key: 'companies.vertical.social',
          },
          {
            title: 'Oracles',
            key: 'companies.vertical.oracles',
          },
          {
            title: 'Supply Chain Management',
            key: 'companies.vertical.supplyChainManagement',
          },
          {
            title: 'Privacy',
            key: 'companies.vertical.privacy',
          },
          {
            title: 'Bridges',
            key: 'companies.vertical.bridges',
          },
        ],
      },
      {
        label: 'Headcount',
        type: 'headcountList',
        key: 'companies.headcount',
        children: [
          {
            label: '',
            type: 'number',
            key: 'companies.headcount.headcountMin',
          },
          {
            label: '',
            type: 'number',
            key: 'companies.headcount.headcountMax',
          },
        ],
        // co
      },
      // {
      //   label: 'Headcount',
      //   type: 'range',
      //   key: 'companies.headcount1',
      //   children: [
      //     {
      //       label: '',
      //       type: 'number',
      //       key: 'companies.headcount.headcountMin',
      //       defaultValue: 1,
      //       steps: 10,
      //     },
      //     {
      //       label: '',
      //       type: 'number',
      //       key: 'companies.headcount.headcountMax',
      //       defaultValue: 10,
      //       steps: 10,
      //     },
      //   ],
      // },
      // {
      //   label: 'Financials',
      //   type: 'alceva',
      //   key: 'companies.financials',
      //   comingSoon: true,
      // },
      {
        label: 'Tags',
        type: 'tags',
        key: 'companies.tags',
        // comingSoon: true,
      },
      // {
      //   label: 'Milestones',
      //   type: 'checkbox',
      //   key: 'companies.milestones',
      //   new: true,
      // },
      {
        label: 'Founding Year',
        type: 'rangeYears',
        key: 'companies.foundingYear',
        children: [
          {
            label: '',
            type: 'number',
            key: 'companies.foundingYear.foundingYearMin',
          },
          {
            label: '',
            type: 'number',
            key: 'companies.foundingYear.foundingYearMax',
          },
        ],
      },
    ],
  },

  // {
  //   label: `Company's HQ`,
  //   image: locationsFilter,
  //   key: 'companyHQ',
  //   value: '',
  //   type: 'expandList',
  //   children: [
  //     {
  //       label: 'Country',
  //       type: 'select',
  //       mode: 'multiple',
  //       key: 'companyHQ',
  //       options: countriesWithPhonePrefix,
  //       haveReset: true,
  //       searchable: true,
  //     },
  //   ],
  // },
  {
    label: 'On - Chain',
    image: onchainFilter,
    tooltip: `This feature allows you to filter companies based on their On-Chain stats ( Volume, TVL, Chain and more) . Available in the `,
    tooltipUnderline: 'Connect+ Plan.',
    comingSoon: !user?.addons?.onChain_filter,
    key: 'onChain',
    value: '',
    type: 'expandList',
    children: [
      {
        label: 'Chains',
        type: 'select',
        mode: 'multiple',
        key: 'onChain.chains',
        options: chainsOptions,
        searchable: true,
        haveReset: true,
      },
      {
        label: 'Token',
        type: 'checkbox',
        key: 'onChain.hasToken',
        haveReset: true,
      },
      {
        label: 'On-Chain Revenue',
        type: 'range',
        haveReset: true,
        key: 'onChain.revenue',
        children: [
          {
            label: '',
            type: 'number',
            key: 'onChain.revenue.revenueMin',
            hasCurrency: true,
            defaultValue: 500000,
            steps: 500000,
          },
          {
            label: '',
            type: 'number',
            key: 'onChain.revenue.revenueMax',
            hasCurrency: true,
            defaultValue: 1000000,
            steps: 500000,
          },
        ],
      },
      // {
      //   label: 'Active wallets',
      //   key: 'onChain.activeWallets',
      //   type: 'tabs',
      //   section: 'activeWallets',
      //   haveReset: true,
      //   children: [
      //     {
      //       label: 'monthly',
      //       type: 'range,',
      //       key: 'onChain.activeWallets.monthly',
      //       children: [
      //         {
      //           defaultValue: 10,
      //           steps: 10,
      //           label: '',
      //           type: 'number',
      //           key: 'onChain.activeWallets.monthly.monthlyMin',
      //         },
      //         {
      //           defaultValue: 100,
      //           label: '',
      //           steps: 10,
      //           type: 'number',
      //           key: 'onChain.activeWallets.monthly.monthlyMax',
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        label: 'Volume (USD)',
        type: 'range',
        haveReset: true,
        key: 'onChain.volume',
        comingSoon: !user?.addons?.volume,
        children: [
          {
            label: '',
            type: 'number',
            key: 'onChain.volume.volumeMin',
            hasCurrency: true,
            defaultValue: 500000,
            steps: 500000,
          },
          {
            label: '',
            type: 'number',
            key: 'onChain.volume.volumeMax',
            hasCurrency: true,
            defaultValue: 1000000,
            steps: 500000,
          },
        ],
      },
      {
        label: 'TVL',
        type: 'range',
        haveReset: true,
        key: 'onChain.tvl',
        comingSoon: !user?.addons?.tvl,
        children: [
          {
            label: '',
            type: 'number',
            key: 'onChain.tvl.tvlMin',
            hasCurrency: true,
            defaultValue: 500000,
            steps: 500000,
          },
          {
            label: '',
            type: 'number',
            key: 'onChain.tvl.tvlMax',
            hasCurrency: true,
            defaultValue: 1000000,
            steps: 500000,
          },
        ],
      },
    ],
  },
  {
    label: 'Signals',
    beta: false,
    image: intentsFilter,
    tooltip: `This feature allows you to filter companies based on signals on their needs and buying signals of products they look for. Available in the `,
    tooltipUnderline: 'Custom Plan.',
    key: 'intents',
    value: '',
    comingSoon: !user?.addons?.signals,
    // type: 'list',
    type: 'expandGroupe',
    children: [
      // {
      //   label: 'Interests',
      //   type: 'interestsCheckbox',
      //   key: 'signals.interests',
      //   new: false,
      //   subType: 'comingSoon',
      //   comingSoon: !user?.addons?.interests,
      // },
      {
        label: 'Milestones',
        type: 'checkbox',
        key: 'companies.milestones',
        // new: true,
      },
      // {
      //   label: 'Sentiments',
      //   type: 'sentimentsCheckbox',
      //   key: 'signals.sentiments',
      //   subType: 'comingSoon',
      //   comingSoon: true,
      // },
    ],
  },
  {
    label: 'Tech Stack & Tools',
    image: techFilter,
    tooltip: `This feature allows you to filter companies based on the tools and technologies they are using for better competitive analysis. Available in the `,
    tooltipUnderline: 'Market Dominance Plan.',
    key: 'techstacktools',
    value: '',
    type: 'sidelist',
    comingSoon: !user?.addons?.tech_stack,
  },
  {
    label: 'Web2 Metrics',
    image: web2Filter,
    tooltip: `This feature allows you to filter companies based on their traffic sources and social media volume. Available in the `,
    tooltipUnderline: 'Connect+ Plan.',
    key: 'web2metrics',
    value: '',
    type: 'expandList',
    comingSoon: !user?.addons?.web2_metrics,
    // userDetails && userDetails !== 'null' && usersBlockedList.includes(JSON.parse(userDetails).email) && true,
    children: [
      {
        label: 'Traffic By Country',
        type: 'select',
        searchable: true,
        key: 'web2metrics.trafficByCountry',
        options: trafficCountries,
        haveReset: true,
      },
      {
        label: 'Visitors per month',
        type: 'range',
        haveReset: true,
        key: 'web2metrics.visitorsPerMonth',
        children: [
          {
            label: '',
            type: 'number',
            key: 'web2metrics.visitorsPerMonth.visitorsPerMonthMin',
            defaultValue: 100,
            steps: 1000,
          },
          {
            label: '',
            type: 'number',
            key: 'web2metrics.visitorsPerMonth.visitorsPerMonthMax',
            defaultValue: 1000,
            steps: 1000,
          },
        ],
      },
      {
        label: 'Social Strength',
        key: 'web2metrics.socialStrength',
        type: 'tabs',
        section: 'socialStrength',
        haveReset: true,
        children: [
          {
            label: 'Twitter',
            type: 'range,',
            key: 'web2metrics.socialStrength.twitter',
            children: [
              {
                label: '',
                type: 'number',
                key: 'web2metrics.socialStrength.twitter.twitterMin',
                defaultValue: 100,
                steps: 100,
              },
              {
                label: '',
                type: 'number',
                key: 'web2metrics.socialStrength.twitter.twitterMax',
                steps: 100,
                defaultValue: 1000,
              },
            ],
          },
          {
            label: 'Discord',
            type: 'range,',
            key: 'web2metrics.socialStrength.discord',
            children: [
              {
                label: '',
                type: 'number',
                key: 'web2metrics.socialStrength.discord.discordMin',
                defaultValue: 100,
                steps: 100,
              },
              {
                label: '',
                type: 'number',
                key: 'web2metrics.socialStrength.discord.discordMax',
                steps: 100,
                defaultValue: 1000,
              },
            ],
          },
          {
            label: 'Telegram',
            type: 'range,',
            key: 'web2metrics.socialStrength.telegram',
            children: [
              {
                label: '',
                type: 'number',
                key: 'web2metrics.socialStrength.telegram.telegramMin',
                defaultValue: 100,
                steps: 100,
              },
              {
                label: '',
                type: 'number',
                key: 'web2metrics.socialStrength.telegram.telegramMax',
                steps: 100,
                defaultValue: 1000,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: 'Revenue',
    image: revenue,
    tooltip: `This feature allows you to filter companies based on their annual revenue . Available in the `,
    tooltipUnderline: 'Connect+ Plan.',
    key: 'revenue',
    value: '',
    // new: true,
    type: 'expandList',
    comingSoon: !user?.addons?.revenue_filter,
    // userDetails && userDetails !== 'null' && usersBlockedList.includes(JSON.parse(userDetails).email) && true,
    children: [
      {
        label: 'Revenue',
        key: 'revenue.revenue',
        type: 'tabs',
        section: 'revenue',
        haveReset: true,
        children: [
          {
            label: 'Total Revenue',
            type: 'range,',
            key: 'revenue.revenue.total_revenue',
            children: [
              {
                label: '',
                type: 'number',
                key: 'revenue.revenue.total_revenue.total_revenueMin',
                hasCurrency: true,
                defaultValue: 500000,
                steps: 500000,
              },
              {
                label: '',
                type: 'number',
                key: 'revenue.revenue.total_revenue.total_revenueMax',
                hasCurrency: true,
                defaultValue: 1000000,
                steps: 500000,
              },
            ],
          },
          {
            label: 'On-Chain',
            type: 'range,',
            key: 'revenue.revenue.onchain',
            children: [
              {
                label: '',
                type: 'number',
                key: 'revenue.revenue.onchain.onchainMin',
                hasCurrency: true,
                defaultValue: 500000,
                steps: 500000,
              },
              {
                label: '',
                type: 'number',
                key: 'revenue.revenue.onchain.onchainMax',
                hasCurrency: true,
                defaultValue: 1000000,
                steps: 500000,
              },
            ],
          },
          {
            label: 'Off-Chain',
            type: 'range,',
            key: 'revenue.revenue.offchain',
            children: [
              {
                label: '',
                type: 'number',
                key: 'revenue.revenue.offchain.offchainMin',
                hasCurrency: true,
                defaultValue: 500000,
                steps: 500000,
              },
              {
                label: '',
                type: 'number',
                key: 'revenue.revenue.offchain.offchainMax',
                hasCurrency: true,
                defaultValue: 1000000,
                steps: 500000,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: 'Funding',
    image: funding_filter,
    tooltip: `This feature allows you to filter companies based on their funding dates. Available in the `,
    tooltipUnderline: 'Market Dominance Plan.',
    key: 'funding',
    value: '',
    type: 'expandList',
    // new: true,
    comingSoon: !user?.addons?.funding_filter,
    //userDetails && userDetails !== 'null' && usersBlockedList.includes(JSON.parse(userDetails).email) && true,
    children: [
      // {
      //   label: 'Funding Dates',
      //   key: 'funding.dates',
      //   type: 'tabs_select',
      //   section: 'funding',
      //   haveReset: true,
      //   children: [
      //     {
      //       label: 'Last Round',
      //       type: 'expandList,',
      //       key: 'funding.dates.last_round',
      //       children: [
      //         {
      //           label: '',
      //           type: 'select',
      //           mode: 'multiple',
      //           key: 'funding.dates.last_round.last_round_option',
      //           options: fundingPeriod,
      //           haveReset: true,
      //           searchable: true,
      //         },
      //       ],
      //     },
      //     {
      //       label: 'Any Round',
      //       type: 'range,',
      //       key: 'funding.dates.any_round',
      //       children: [
      //         {
      //           label: 'Choose option',
      //           type: 'select',
      //           mode: 'multiple',
      //           key: 'funding.dates.any_round.any_round_option',
      //           options: fundingPeriod,
      //           haveReset: true,
      //           searchable: true,
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        label: 'Funding Date',
        type: 'select',
        searchable: false,
        key: 'funding.fundingDate',
        options: fundingDates,
        haveReset: true,
      },
      {
        label: 'Last Funding Amount',
        type: 'range',
        haveReset: true,
        key: 'funding.lastFundingAmount',
        children: [
          {
            label: '',
            type: 'number',
            key: 'funding.lastFundingAmount.lastFundingAmountMin',
            defaultValue: 0,
            steps: 500000,
            hasCurrency: true,
          },
          {
            label: '',
            type: 'number',
            key: 'funding.lastFundingAmount.lastFundingAmountMax',
            defaultValue: 500000,
            steps: 500000,
            hasCurrency: true,
          },
        ],
      },
      {
        label: 'Total Funding Amount',
        type: 'range',
        haveReset: true,
        key: 'funding.totalFundingAmount',
        children: [
          {
            label: '',
            type: 'number',
            key: 'funding.totalFundingAmount.totalFundingAmountMin',
            defaultValue: 0,
            steps: 500000,
            hasCurrency: true,
          },
          {
            label: '',
            type: 'number',
            key: 'funding.totalFundingAmount.totalFundingAmountMax',
            defaultValue: 500000,
            steps: 500000,
            hasCurrency: true,
          },
        ],
      },
      // {
      //   label: 'Funding Round Type',
      //   key: 'funding.round_type',
      //   type: 'tabs_checkbox',
      //   section: 'funding',
      //   haveReset: true,
      //   children: [
      //     {
      //       label: 'Last Round',
      //       type: 'expandList,',
      //       key: 'funding.round_type.last_round',
      //       children: [
      //         {
      //           label: 'Choose option',
      //           type: 'select',
      //           mode: 'multiple',
      //           key: 'funding.round_type.any_round.last_round_items',
      //           options: countriesWithPhonePrefix,
      //           haveReset: true,
      //           searchable: true,
      //         },
      //       ],
      //     },
      //     {
      //       label: 'Any Round',
      //       type: 'range,',
      //       key: 'funding.round_type.any_round',
      //       children: [
      //         {
      //           label: 'Choose option',
      //           type: 'select',
      //           mode: 'multiple',
      //           key: 'funding.round_type.any_round.any_round_items',
      //           options: countriesWithPhonePrefix,
      //           haveReset: true,
      //           searchable: true,
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   label: 'Connect +',
  //   key: '',
  //   value: '',
  //   open: true,
  //   image: PulseAnimation,
  //   component: <ConnectPlusFilter route={'companies'} />,
  //   className: 'purple',
  // },
]
